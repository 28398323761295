@import "bootstrap";
@import "spinners";
@import "aniloader";
@import "bootstrap";
//@import "will_paginate";

// @import "mainstyle.scss";

@import "mainstyle";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";

// @import "font-awesome/scss/font-awesome.scss";
// @import "font-awesome";

@import "bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone";
@import "trix/dist/trix";
@import 'simple-datatables/style.css';

.datepicker table tr td span.active.active {
	background-color: #6bd098;
	background-image: none;
}
.datepicker table tr td span.active:hover.active{
	background-color: #5fbb88;
}

.sticky-top {
        position: fixed;
        top: 1.0cm;
        right: 1.0cm;
    }

.fia-w27{
	width: 27px !important;
}

@for $i from 1 through 100 {

	// ================ Margin ================
  .wdt-#{$i}0 {
    width: #{$i}0px;
  }

  .m-#{$i}0 {
		margin: #{$i}0px !important;
	}

	.mr-#{$i}0 {
		margin-right: #{$i}0px !important;
	}
	
	.ml-#{$i}0 {
		margin-left: #{$i}0px !important;
	}
	
	.mt-#{$i}0 {
		margin-top: #{$i}0px !important;
	}
	
	.mb-#{$i}0 {
		margin-bottom: #{$i}0px !important;
	}

	// ================ Padding ================
	.p-#{$i}0 {
		padding: #{$i}0px !important;
	}

	.pr-#{$i}0 {
		padding-right: #{$i}0px !important;
	}
	
	.pl-#{$i}0 {
		padding-left: #{$i}0px !important;
	}
	
	.pt-#{$i}0 {
		padding-top: #{$i}0px !important;
	}
	
	.pb-#{$i}0 {
		padding-bottom: #{$i}0px !important;
	}
}

.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

// @for $i from 1 through 9 {
//   .fw-#{$i}00{
//     font-weight: #{$i}00 !important;
//   }
// }

.fw-500 {
  font-weight: 500 !important;
}
/*Simple radio btn input CSS*/
.custom-control-label {
  cursor: pointer;
}

.cap-opt-1 {
  display: inline-block;
  min-width: 50px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #59a9f7;
  background-color: #59a9f7;
}

/*Card Button CSS*/

.card-radio-btn {
  .content_head {
    color: #333;
    font-size: 1.2rem;
    line-height: 30px;
    font-weight: 500;
  }

  .content_sub {
    color: #9e9e9e;
    font-size: 14px;
  }
}

.card-input-element {
  + .card {
    // margin: 10px;
    // justify-content: center;
    // color: var(--primary);
    // -webkit-box-shadow: none;
    // box-shadow: none;
    // border: 2px solid transparent;
    // border-radius: 10px;
    // text-align: center;
    // -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    margin: 10px;
    justify-content: center;
    color: #007bff;
    color: var(--primary);
    box-shadow: none;
    border: 2px solid #eff3f4;
    border-radius: 10px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  &:checked + .card {
    border: 2px solid #6bd098;
    -webkit-transition: border 0.3s;
    -o-transition: border 0.3s;
    transition: border 0.3s;

    &::after {
      content: "\f058";
      color: rgba(107, 208, 152, 0.6);
      position: absolute;
      right: 5px;
      top: 5px;
      font-family: "Font Awesome 5 Free";
      font-style: normal;
      font-size: 1rem;
      font-weight: 900;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-animation-name: fadeInCheckbox;
      animation-name: fadeInCheckbox;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }

  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

div.hr-or {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  text-align: center;
  height: 0px;
  line-height: 0px;
}

div.hr-or:before {
  content: 'OR';
  background-color: #fff;
}

div.hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  height: 0px;
  line-height: 0px;
}

.hr-title {
  background-color: #fff;
  font-size: 16px;
}

.payment-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.payment-loader .binding {
  content: '';
  width: 60px;
  height: 4px;
  border: 2px solid #9b72ee;
  margin: 0 auto;
}

.payment-loader .pad {
  width: 74px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #20c997;
  padding: 6px;
  margin: 0 auto;
}

.payment-loader .chip {
  width: 12px;
  height: 8px;
  background: #20c997;
  border-radius: 3px;
  margin-top: 4px;
  margin-left: 3px;
}

.payment-loader .line {
  width: 52px;
  margin-top: 6px;
  margin-left: 3px;
  height: 4px;
  background: #20c997;
  border-radius: 100px;
  opacity: 0;
  -webkit-animation: writeline 3s infinite ease-in;
  -moz-animation: writeline 3s infinite ease-in;
  -o-animation: writeline 3s infinite ease-in;
  animation: writeline 3s infinite ease-in;
}

.payment-loader .line2 {
  width: 32px;
  margin-top: 6px;
  margin-left: 3px;
  height: 4px;
  background: #20c997;
  border-radius: 100px;
  opacity: 0;
  -webkit-animation: writeline2 3s infinite ease-in;
  -moz-animation: writeline2 3s infinite ease-in;
  -o-animation: writeline2 3s infinite ease-in;
  animation: writeline2 3s infinite ease-in;
}

.payment-loader .line:first-child {
  margin-top: 0;
}

.payment-loader .line.line1 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.payment-loader .line.line2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.payment-loader .loader-text {
  p:nth-child(1) {
    color: #2c2c2c;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;

    animation-delay: 10s;
    animation: textline1 3s infinite ease-in;
  }

  p:nth-child(2) {
    margin-top: 10px;
    color: #2c2c2c;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    animation-delay: 2s;
    animation: textline2 3s infinite ease-in;
  }
}

@keyframes writeline {
  0% {
    width: 0px;
    opacity: 0;
  }

  33% {
    width: 52px;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes writeline2 {
  0% {
    width: 0px;
    opacity: 0;
  }

  33% {
    width: 32px;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes textline1 {
  0% {
    width: 0%;
    opacity: 0;
  }

  33% {
    width: 100%;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes textline2 {
  0% {
    width: 100%;
    opacity: 0;
  }

  33% {
    width: 100%;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
